<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap19UnitTests"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 19: Unit Tests" image-name="tests.jpg" image-alt="Unit Tests"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What are unit tests</p></li>
<li><p>How to write unit tests</p></li>
<li><p>How to test your Go program with the go tool</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Unit tests</p></li>
<li><p>Test case</p></li>
<li><p>Test function</p></li>
<li><p>Assertion</p></li>
<li><p>Test-Driven Development (TDD)</p></li>
<li><p>Code coverage</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is a function :</p>
<pre v-highlightjs><code class="go" v-pre >// compute and return the total price of a hotel booking
// all amounts in input must be multiplied by 100. Currency is Dollar
// the amount returned must be divided by 100. (ex: 10132 =&gt; 101.32 $)
func totalPrice(nights, rate, cityTax uint) uint {
   return nights*rate + cityTax
}</code></pre>
<p>This function computes the price of a booking. It seems right, no? How to be sure that the amount returned is correct? We can run it with some data as argument and check its result :</p>
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;fmt&quot;

func main() {
   price := totalPrice(3, 10000, 132)
   fmt.Println(price)
}</code></pre>
<p>The program outputs <span v-highlightjs><code class="go" v-pre style="display: inline">30132</code></span> which is equivalent to <span v-highlightjs><code class="go" v-pre style="display: inline">301.32$</code></span>. We should divide it by 100 to get an amount in dollars.</p>
<p>Is it correct? Let’s compute it by hand. The total price of a room is the number of nights multiplied by the rate plus the city tax : <span class="math inline">3\times(100+1,32)=3\times101.32=303.96</span>. Have you spotted the bug in the function?</p>
<p>This statement :</p>
<pre v-highlightjs><code class="go" v-pre >return nights*rate + cityTax</code></pre>
<p>It should be replaced by this one :</p>
<pre v-highlightjs><code class="go" v-pre >return nights * (rate + cityTax)</code></pre>
<p>This way, the function returns the right answer. What if our program checks it directly?</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/intro/main.go
package main

import &quot;fmt&quot;

//...

func main() {
    price := totalPrice(3, 10000, 132)
    if price == 30396 {
        fmt.Println(&quot;function works&quot;)
    } else {
        fmt.Println(&quot;function is buggy&quot;)
    }
}</code></pre>
<p>The program itself will check if the function implementation is correct. No surprises it outputs the <strong>function works</strong> ! This <strong>program is a unit test!</strong></p>
<div id="what-is-unit-testing" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is unit testing? <a href="#what-is-unit-testing"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>If we take the definition from the IEEE (Institute for Electrical and Electronics Engineers) <b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link> unit testing is the “testing of individual hardware or software units or groups of related units”.</p>
<p>We take individual parts of the system to test them. In other words, we check that individual parts of the system work. The whole system is not tested.</p>
<p>The unit test is created and run by the developer of the code. With this tool, we can check that our methods and functions run as expected. Unit tests are focused exclusively on checking that those small programming units are working.</p>
<p>Some developers will argue that unit tests are useless. Often they say that when they develop their code, they are testing permanently that the system work. For instance, a web developer that has to create a website will often have two screens, the one with the source code and the one with the program running. When he wants to implement something new, he will start with the code and checks if it works.</p>
<p>This process is purely manual and depends on the experience of the developer on the system. A newly hired might not detect errors and breaking changes. What if we can run those tests automatically? Imagine that you can run those tests each time you build your Go program! Or even better, you could run them each time you make a change to a package!</p>
<div id="what-is-a-test-case-a-test-set-an-assertion" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> What is a test case, a test set, an assertion? <a href="#what-is-a-test-case-a-test-set-an-assertion"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A single unit test is called a test case. A group of test cases is called a test set (or test suite).</p>
<p>To better understand what a test case is, let’s take an example. Imagine that you have developed a function to capitalize a string. We will build a test case to check it.</p>
<p>Our test case will be composed of :</p>
<ul>
<li><p>A <strong>test input</strong>. For instance : “coffee”</p></li>
<li><p>An <strong>expected output</strong> : In our example, it will be the string “COFFEE”</p></li>
<li><p>The <strong>actual output</strong> of our function under test</p></li>
<li><p>A way to <strong>assert</strong> that the actual returned value of our function is the one that is expected. We could use string comparison features of Go to check that the two strings are equal. We can also use a Go package to do it. This part of the unit test is called the <strong>assertion</strong></p></li>
</ul>
<div id="why-unit-testing-your-code" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Why Unit testing your code? <a href="#why-unit-testing-your-code"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this section, I will go through some reasons extracted from an IEEE survey about unit testing <b-link class="citation" data-cites="runeson2006survey" href="#runeson2006survey" >[@runeson2006survey]</b-link>:</p>
<ul>
<li><p>Unit tests will control that functions and methods work as expected. Without unit tests, developers test their functionality during the development phase. Those tests are not <strong>reproducible</strong>. After the development of the feature, those manual tests are no longer run.</p></li>
<li><p>If they write their tests into the project sources, they can run those tests later. They <strong>protect the project against nasty regressions</strong> (development of new features breaks something in the code of another one).</p></li>
<li><p>The presence of unit test can be <strong>a customer requirement</strong>. It seems to be pretty rare, but some specifications include test coverage requirements.</p></li>
<li><p>A <strong>better focus on API design</strong> is also generally observed when developers write unit tests. You have to call the function you are developing; as a consequence you can see improvements. This focus is even bigger if you use the TDD method.</p></li>
<li><p>Unit tests also serve as <strong>code documentation</strong>. Users that want to know how to call a specific function can take a look at the unit test to get their answer immediately.</p></li>
</ul>
<div id="where-to-put-the-tests" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Where to put the tests? <a href="#where-to-put-the-tests"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Several languages are putting the tests into a specific directory, often called <strong>tests.</strong> In Go unit tests are living next to the code they test. Tests are part of the package under test.</p>
<p>Here is the list of files from the directory src/strings :</p>
<figure>
<b-img :src="require('@/assets/images/strings_dir_tree.png')" alt="strings package directory"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">strings package directory</figcaption>
</figure>
<p>You can see that there is a naming pattern: there is a file named <strong>xxx_test.go</strong> for each file named <strong>xxx.go.</strong></p>
<p>When you build your program, the file named xxx_test.go will be ignored by the compiler.</p>
<div id="how-to-write-a-basic-unit-test" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> How to write a basic unit test <a href="#how-to-write-a-basic-unit-test"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s write our very first unit test together. We will test the package foo :</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/basic/foo/foo.go
package foo

import &quot;fmt&quot;

func Foo() string {
    return fmt.Sprintf(&quot;Foo&quot;)
}</code></pre>
<div id="the-test-file" class="anchor"></div>
<h4 data-number="7.0.0.1"><span class="header-section-number">7.0.0.1</span> The test file <a href="#the-test-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s create a file <span v-highlightjs><code class="go" v-pre style="display: inline">foo_test.go</code></span> in the same folder as <span v-highlightjs><code class="go" v-pre style="display: inline">foo.go</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/basic/foo/foo_test.go 
package foo

import &quot;testing&quot;

func TestFoo(t *testing.T) {

}</code></pre>
<p>You can see that this source file is part of the <strong>foo</strong> package. We have imported the package testing from the standard library (that we will use later).</p>
<p>A single function is defined: TestFoo. This function takes as input a pointer to testing.T (*testing.T).</p>
<div id="naming-of-a-test-case" class="anchor"></div>
<h4 data-number="7.0.0.2"><span class="header-section-number">7.0.0.2</span> Naming of a test case <a href="#naming-of-a-test-case"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>A test function should be named following the same convention :</p>
<figure>
<b-img :src="require('@/assets/images/test_function_name.png')" alt="Test function signature"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Test function signature</figcaption>
</figure>
<ul>
<li><p>The first part of the test function name is the word Test. It is fixed. It’s always <span v-highlightjs><code class="go" v-pre style="display: inline">"Test"</code></span></p></li>
<li><p>The second part is often the name of the function you want to test. It must start with a capital letter.</p></li>
</ul>
<div id="the-contents-of-a-test-case" class="anchor"></div>
<h4 data-number="7.0.0.3"><span class="header-section-number">7.0.0.3</span> The contents of a test case <a href="#the-contents-of-a-test-case"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Here is an example of the function <span v-highlightjs><code class="go" v-pre style="display: inline">Foo</code></span>. The function has no argument, but it always returns the string “Foo”. If we want to unit test it, we will assert (verify) that the return of the function is <span v-highlightjs><code class="go" v-pre style="display: inline">"Foo"</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/basic/foo/foo_test.go
package foo

import &quot;testing&quot;

func TestFoo(t *testing.T) {
    expected := &quot;Foo&quot;
    actual := Foo()
    if expected != actual {
        t.Errorf(&quot;Expected %s do not match actual %s&quot;, expected, actual)
    }
}</code></pre>
<p>We first define a variable <span v-highlightjs><code class="go" v-pre style="display: inline">expected</code></span> that holds our expected result. Then we define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">actual</code></span> that will hold the actual return value of the function <span v-highlightjs><code class="go" v-pre style="display: inline">Foo</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">foo</code></span>.</p>
<p>Please remember those two terms : <strong>actual</strong> and <strong>expected</strong>. They are classic variables names in the context of testing.</p>
<ul>
<li><p>The <strong>expected</strong> variable is the result as expected by the user.</p></li>
<li><p>The <strong>actual</strong> variable holds the execution result of the unit of the code we want to test.</p></li>
</ul>
<p>Then the test continues with an <strong>assertion</strong>. We test the equality between the actual value and the expected one. If that’s not the case, we make the test fail by using a t.Errorf method (that is defined on the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">testing</code></span>) :</p>
<pre v-highlightjs><code class="go" v-pre >t.Errorf(&quot;Expected %s do not match actual %s&quot;, expected, actual)</code></pre>
<div id="about-success-and-errors" class="anchor"></div>
<h4 data-number="7.0.0.4"><span class="header-section-number">7.0.0.4</span> About Success and Errors <a href="#about-success-and-errors"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>There is no method defined on the type <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> to signal the test success.</p>
<p>When the test function returns without calling a failure method, then it is interpreted as a success.</p>
<div id="failure-signal" class="anchor"></div>
<h4 data-number="7.0.0.5"><span class="header-section-number">7.0.0.5</span> Failure signal <a href="#failure-signal"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>To signal a failure, you can use the following methods :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Error</code></span> : will log and marks the test function as failed. Execution will continue.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Errorf</code></span> : will log (with the specified format) and marks the test function as failed. Execution will continue.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Fail</code></span> : will mark the function as failing. Execution will continue.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">FailNow</code></span> : this one will mark the test as failed and stop the execution of the current test function (if you have other assertions, they will not be tested).</p></li>
</ul>
<p>You also have the methods <span v-highlightjs><code class="go" v-pre style="display: inline">Fatal</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Fatalf</code></span> that will log and call internally <span v-highlightjs><code class="go" v-pre style="display: inline">FailNow</code></span>.</p>
<div id="test-files" class="anchor"></div>
<h4 data-number="7.0.0.6"><span class="header-section-number">7.0.0.6</span> Test files <a href="#test-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Sometimes you need to store files that will support your unit tests. For instance, an example of a configuration file, a model CSV file (for an application that will generate files)...</p>
<p>Store those files into the <span v-highlightjs><code class="go" v-pre style="display: inline">testdata</code></span> folder.</p>
<figure>
<b-img :src="require('@/assets/images/testdatadir_archive_zip.png')" alt="The testdata folder inside a package can hold files used in tests (tree view of standard library archive/zip)"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The testdata folder inside a package can hold files used in tests (tree view of standard library archive/zip)</figcaption>
</figure>
<div id="assertion-libraries" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Assertion libraries <a href="#assertion-libraries"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The Go standard library gives all the necessary tools to build your unit test without external libraries. Despite this fact, it is common to see projects that use external “assertion libraries”. An assertion library exposes many functions and methods to build assertions. One very popular module is <strong>github.com/stretchr/testify.</strong></p>
<p>To add it to your project, type the following command in your terminal :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get github.com/stretchr/testify</code></pre>
<p>As an example, this is the previous unit test written with the help of the package <span v-highlightjs><code class="go" v-pre style="display: inline">assert</code></span> from the module <span v-highlightjs><code class="go" v-pre style="display: inline">github.com/stretchr/testify</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/assert-lib/foo/foo_test.go
package foo

import (
    &quot;testing&quot;

    &quot;github.com/stretchr/testify/assert&quot;
)

func TestFoo(t *testing.T) {
    assert.Equal(t, &quot;Foo&quot;, Foo(), &quot;they should be equal&quot;)
}</code></pre>
<p>Other libraries exist a quick search on GitHub can give you some additional references: https://github.com/search?l=Go&amp;q=assertion+library&amp;type=Repositories</p>
<div id="how-to-run-unit-test" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> How to run unit test <a href="#how-to-run-unit-test"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="run-the-tests-of-a-specific-package" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Run the tests of a specific package <a href="#run-the-tests-of-a-specific-package"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To run your unit tests, you have to use the command-line interface. Open a terminal and cd to your project directory :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd go/src/gitlab.com/loir402/foo</code></pre>
<p>Then run the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test</code></pre>
<p>The following output result is displayed in the terminal :</p>
<pre v-highlightjs><code class="go" v-pre >PASS
ok     gitlab.com/loir402/foo 0.005s</code></pre>
<p>This command will run all the unit tests for the package located into the current directory. For instance, if you want to run the unit tests of the <strong>path</strong> package of the current directory :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd /usr/local/go/src/path
$ go test</code></pre>
<div id="run-all-the-tests-of-a-project" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Run all the tests of a project <a href="#run-all-the-tests-of-a-project"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can run all the unit tests of your current project by launching the command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test ./...</code></pre>
<div id="test-failure" class="anchor"></div>
<h2 data-number="9.3"><span class="header-section-number">9.3</span> Test failure <a href="#test-failure"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>What is the output of a failed unit test? Here is an example. We have modified our unit test to make it crash. Instead of the string <span v-highlightjs><code class="go" v-pre style="display: inline">"Foo"</code></span> we are no expecting <span v-highlightjs><code class="go" v-pre style="display: inline">"Bar"</code></span>. Consequently, the test fails.</p>
<pre v-highlightjs><code class="go" v-pre >$ go test
--- FAIL: TestFoo (0.00s)
    foo_test.go:9: Expected Bar do not match actual Foo
FAIL
exit status 1
FAIL   gitlab.com/loir402/foo 0.005s</code></pre>
<p>You can note that the test result is more verbose in the case of a failure. It will indicate which test case fail by printing the test case name (<span v-highlightjs><code class="go" v-pre style="display: inline">TestFoo</code></span> ). it will also give you the line of the test that fails (<span v-highlightjs><code class="go" v-pre style="display: inline">foo_test.go:9</code></span>).</p>
<p>Then you can see that the system is printing the error message that we have told him to print in the case of a failure.</p>
<p>The program exits with a status code of 1, allowing you to autodetect it if you want to create continuous integration tools.</p>
<div id="exit-code-or-exit-status" class="anchor"></div>
<h4 data-number="9.3.0.1"><span class="header-section-number">9.3.0.1</span> Exit Code (or exit status) <a href="#exit-code-or-exit-status"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>An exit code different from 0 signals an error.</p></li>
<li><p>An exit code of 0 signals NO errors</p></li>
</ul>
<div id="go-test-and-go-vetsubsecgo-testvet" class="anchor"></div>
<h2 data-number="9.4"><span class="header-section-number">9.4</span> go test and go vet<span id="subsec:Go-testvet" label="subsec:Go-testvet">[subsec:Go-testvet]</span> <a href="#go-test-and-go-vetsubsecgo-testvet"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you run the <span v-highlightjs><code class="go" v-pre style="display: inline">go test</code></span> command, the go tool will also run <span v-highlightjs><code class="go" v-pre style="display: inline">go vet</code></span> automatically on the tested packages (source of the packages and test files).</p>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">go vet</code></span> command is part of the Go toolchain. It performs syntax verification on your source code to detect potential errors.</p>
<p>This command has a whole list of checks; when you run a go test, only a small subset is launched :</p>
<dl>
<dt>atomic</dt>
<dd><p>will detect the bad usages of the package sync/atomic</p>
</dd>
<dt>bool</dt>
<dd><p>this check will verify the usages of boolean conditions.</p>
</dd>
<dt>buildtags</dt>
<dd><p>when you run a go test you can specify build tags to the command line, this check will verify that you have correctly formed build tags in the command you type.</p>
</dd>
<dt>nilfunc</dt>
<dd><p>checks that you never compare a function with nil</p>
</dd>
</dl>
<p>Running a set of <span v-highlightjs><code class="go" v-pre style="display: inline">go vet</code></span> command automatically before launching unit tests is a brilliant idea. It can make you discover mistakes before they cause harm to your program!</p>
<div id="just-compile" class="anchor"></div>
<h2 data-number="9.5"><span class="header-section-number">9.5</span> Just compile <a href="#just-compile"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To compile our test without running it, you can type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -c</code></pre>
<p>This will create a test binary called “<strong>packageName.test</strong>”.</p>
<div id="how-to-write-a-table-test" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> How to write a table test <a href="#how-to-write-a-table-test"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous example, we tested our function against one expected result. In a real situation, you might want to test your function with several test cases.</p>
<p>One approach could be to build a test function like that :</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/table-test/price/price_test.go
package price

import &quot;testing&quot;

func Test_totalPrice1(t *testing.T) {
    // test case 1
    expected := uint(0)
    actual := totalPrice(0, 150, 12)
    if expected != actual {
        t.Errorf(&quot;Expected %d does not match actual %d&quot;, expected, actual)
    }
    // test case 2
    expected = uint(112)
    actual = totalPrice(1, 100, 12)
    if expected != actual {
        t.Errorf(&quot;Expected %d does not match actual %d&quot;, expected, actual)
    }

    // test case 3
    expected = uint(224)
    actual = totalPrice(2, 100, 12)
    if expected != actual {
        t.Errorf(&quot;Expected %d does not match actual %d&quot;, expected, actual)
    }

}</code></pre>
<ul>
<li><p>We have 3 test cases; each test case follow the previous one</p></li>
<li><p>This is a good approach; it works as expected. However, we can use the table test approach that can be more convenient :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// unit-test/table-test/price/price_test.go
package price

import &quot;testing&quot;

func Test_totalPrice(t *testing.T) {
    type parameters struct {
        nights  uint
        rate    uint
        cityTax uint
    }
    type testCase struct {
        name string
        args parameters
        want uint
    }
    tests := []testCase{
        {
            name: &quot;test 0 nights&quot;,
            args: parameters{nights: 0, rate: 150, cityTax: 12},
            want: 0,
        },
        {
            name: &quot;test 1 nights&quot;,
            args: parameters{nights: 1, rate: 100, cityTax: 12},
            want: 112,
        },
        {
            name: &quot;test 2 nights&quot;,
            args: parameters{nights: 2, rate: 100, cityTax: 12},
            want: 224,
        },
    }
    for _, tt := range tests {
        t.Run(tt.name, func(t *testing.T) {
            if got := totalPrice(tt.args.nights, tt.args.rate, tt.args.cityTax); got != tt.want {
                t.Errorf(&quot;totalPrice() = %v, want %v&quot;, got, tt.want)
            }
        })
    }
}</code></pre>
<ul>
<li><p>We create a type struct named <span v-highlightjs><code class="go" v-pre style="display: inline">parameters</code></span>. Each field of that struct is a function parameter of the function under test</p></li>
<li><p>Then we create a struct named <span v-highlightjs><code class="go" v-pre style="display: inline">testCase</code></span>. With three fields:</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">name</code></span>: the name of the test case: a human-readable name</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">args</code></span>: the parameters to give to the function under test</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">want</code></span>: the expected value returned by the function</p></li>
</ul></li>
<li><p>A slice named <span v-highlightjs><code class="go" v-pre style="display: inline">tests</code></span> containing elements of type <span v-highlightjs><code class="go" v-pre style="display: inline">testCase</code></span> is created. This is here that we will manually define each test case</p>
<ul>
<li>One test case = one element of the slice.</li>
</ul></li>
<li><p>Then with a for loop, we iterate over the elements of the slice <span v-highlightjs><code class="go" v-pre style="display: inline">tests</code></span>.</p></li>
<li><p>At each iteration, we call the method <span v-highlightjs><code class="go" v-pre style="display: inline">t.Run</code></span></p>
<ul>
<li><p>Parameters :</p>
<ul>
<li><p>the test name <span v-highlightjs><code class="go" v-pre style="display: inline">tt.name</code></span></p></li>
<li><p>An anonymous function that contains the test to run (its signature is similar to a standard test case)</p></li>
</ul></li>
</ul></li>
<li><p>At each iteration, we compare what we got (the actual value) to what we expect.</p></li>
</ul>
<p>Here is the run output of this test (successful) :</p>
<pre v-highlightjs><code class="go" v-pre >=== RUN   Test_totalPrice
=== RUN   Test_totalPrice/test_0_nights
=== RUN   Test_totalPrice/test_1_nights
=== RUN   Test_totalPrice/test_2_nights
--- PASS: Test_totalPrice (0.00s)
    --- PASS: Test_totalPrice/test_0_nights (0.00s)
    --- PASS: Test_totalPrice/test_1_nights (0.00s)
    --- PASS: Test_totalPrice/test_2_nights (0.00s)
PASS</code></pre>
<ul>
<li><p>The run result gives the information that three subtests are run.</p></li>
<li><p>It also gives the result for each test along with the name of the test.</p></li>
</ul>
<div id="the-two-go-test-modes" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> The two go test modes <a href="#the-two-go-test-modes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">go test</code></span> is a command that we can run in two different modes :</p>
<div id="local-directory-mode" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> Local directory mode <a href="#local-directory-mode"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This mode is triggered when you run the command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test</code></pre>
<p>Here nothing is added, just <span v-highlightjs><code class="go" v-pre style="display: inline">go test</code></span>. In this mode, Go will build the package located in the current directory.</p>
<p>All unit tests of the project will not be executed, but only the ones defined at the current package level. Some IDEs will run this command each time you save a source file; that’s a pretty good idea because each time you modify a package file, you can check that the unit tests are passing.</p>
<div id="package-list-mode" class="anchor"></div>
<h2 data-number="11.2"><span class="header-section-number">11.2</span> Package list mode <a href="#package-list-mode"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In this mode, you can ask Go to unit test some specific packages or all the project packages.</p>
<p>For instance, if you have a project that defines a <span v-highlightjs><code class="go" v-pre style="display: inline">pkgName</code></span> strings you can run the following command :</p>
<pre v-highlightjs><code class="go" v-pre >go test modulePath/pkgName</code></pre>
<p>This command will work in any project directory. It will run the test of the package <span v-highlightjs><code class="go" v-pre style="display: inline">pkgName</code></span> from the module <span v-highlightjs><code class="go" v-pre style="display: inline">modulePath</code></span>.</p>
<div id="caching" class="anchor"></div>
<h2 data-number="11.3"><span class="header-section-number">11.3</span> Caching <a href="#caching"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you are <strong>in package list mode</strong> go will cache the test result of <strong>successful</strong> tests. This mechanism has been developed to avoid testing packages multiple time.</p>
<p>To test this behavior, launch the tests on the package strings :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test strings</code></pre>
<p>It will output the following :</p>
<pre v-highlightjs><code class="go" v-pre >ok     strings    4.256s</code></pre>
<p>You can see here that the unit test’s time is 4.256s, which is quite long.</p>
<p>Try to launch it again :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test strings
ok     strings    (cached)</code></pre>
<p>You can see here that the result is instantaneous, and instead of the duration, the (cached) is displayed. It means that go has retrieved the cached version of the test result.</p>
<div id="disabling-cache" class="anchor"></div>
<h4 data-number="11.3.0.1"><span class="header-section-number">11.3.0.1</span> Disabling cache <a href="#disabling-cache"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Note that when you modify a test file or a source file of the package, the test result that has been cached is invalidated, and the test will be effectively run.</p>
<p>To disable caching, you can use the following command flag :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test strings -count=1</code></pre>
<div id="environnement-variables-and-cache" class="anchor"></div>
<h4 data-number="11.3.0.2"><span class="header-section-number">11.3.0.2</span> Environnement variables and cache <a href="#environnement-variables-and-cache"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>If you are using environment variables in your source files, Go will cache the test result if the environment variables set are not changing.</p>
<p>Let’s take an example, imagine that you are using the environment variable <span v-highlightjs><code class="go" v-pre style="display: inline">MYENV</code></span> inside your test script :</p>
<pre v-highlightjs><code class="go" v-pre >func TestFoo(t *testing.T) {
   env := os.Getenv(&quot;MYENV&quot;)
   fmt.Println(env)
   //..
}</code></pre>
<p>The first time, when you execute the test with the environment variable set to <span v-highlightjs><code class="go" v-pre style="display: inline">"BAR"</code></span>, then the test will run :</p>
<pre v-highlightjs><code class="go" v-pre >$ export MYENV=BAR &amp;&amp; go test gitlab.com/loir402/foo
ok     gitlab.com/loir402/foo 0.005s</code></pre>
<p>At the second run of the same command, Go will retrieve the test result directly from cache :</p>
<pre v-highlightjs><code class="go" v-pre >ok     gitlab.com/loir402/foo (cached)</code></pre>
<p>But if you change the value of the environment variable <span v-highlightjs><code class="go" v-pre style="display: inline">MYENV</code></span> then the test will be executed :</p>
<pre v-highlightjs><code class="go" v-pre >$ export MYENV=CORGE &amp;&amp; go test gitlab.com/loir402/foo
ok     gitlab.com/loir402/foo 0.005s</code></pre>
<div id="open-files" class="anchor"></div>
<h4 data-number="11.3.0.3"><span class="header-section-number">11.3.0.3</span> Open files <a href="#open-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The same mechanism is in place when your code opens a file. If you run your test for the first time, Go will cache the result. But if the file has changed, the result is no longer cached, and the test is executed again :</p>
<pre v-highlightjs><code class="go" v-pre >func TestFoo(t *testing.T) {
   d, err := ioutil.ReadFile(&quot;testdata/lol.txt&quot;)
   if err != nil {
      t.Errorf(&quot;impossible to open file&quot;)
   }
   fmt.Print(string(d))
   //..
}</code></pre>
<p>Here we open the file <strong>testdata/lol.txt.</strong> If we run the test for the first time, it is executed, and it’s cached.</p>
<p>If we modify the content of testdata/lol.txt and rerun the test, it will be executed because the file’s content has changed, then the test conditions are not the same.</p>
<div id="running-unit-test-in-parallel" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Running unit test in parallel <a href="#running-unit-test-in-parallel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In a big project, the number of unit tests can become very large. Running the unit tests can become time-consuming for the team.</p>
<p>You need to add a call to the <span v-highlightjs><code class="go" v-pre style="display: inline">Parallel</code></span> method from the package testing to allow your test to be run concurrently by the go command line.</p>
<p>Let’s take an example:</p>
<pre v-highlightjs><code class="go" v-pre >func TestCorge1(t *testing.T) {
   time.Sleep(300 * time.Millisecond)
}

func TestCorge2(t *testing.T) {
   time.Sleep(300 * time.Millisecond)
}

func TestCorge3(t *testing.T) {
   time.Sleep(300 * time.Millisecond)
}</code></pre>
<p>Here we have 3 unit tests, that are testing nothing but that just wait for 300 milliseconds each. We did not add any assertion on purpose to facilitate the reading of the source code.</p>
<p>Let’s run those tests</p>
<pre v-highlightjs><code class="go" v-pre >$ go test</code></pre>
<p>The test result is the following :</p>
<pre v-highlightjs><code class="go" v-pre >PASS
ok     gitlab.com/loir402/corge   0.913s</code></pre>
<p>Tests are taking 0.913seconds to run which is roughly <span class="math inline">3\times300ms</span>.</p>
<p>Let’s make them run in parallel :</p>
<pre v-highlightjs><code class="go" v-pre >func TestCorge1(t *testing.T) {
   t.Parallel()
   time.Sleep(300 * time.Millisecond)
}

func TestCorge2(t *testing.T) {
   t.Parallel()
   time.Sleep(300 * time.Millisecond)
}

func TestCorge3(t *testing.T) {
   t.Parallel()
   time.Sleep(300 * time.Millisecond)
}</code></pre>
<p>Here we just added</p>
<pre v-highlightjs><code class="go" v-pre >t.Parallel()</code></pre>
<p>at the beginning of the test. This simple method call will increase the running speed of our test :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test
PASS
ok     gitlab.com/loir402/corge   0.308s</code></pre>
<p>We have divided the running time by 3 ! This gain of time is precious for the development team, so use this feature when you build your unit tests !</p>
<div id="advanced-usage-of-the-go-test-command" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Advanced usage of the go test command <a href="#advanced-usage-of-the-go-test-command"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="arguments--args" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Arguments (-args) <a href="#arguments--args"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can build a test that will accept command line arguments. Those arguments can be passed to the test executable by using a flag. Let’s take an example of a test that requires command-line arguments.</p>
<pre v-highlightjs><code class="go" v-pre >func TestArgs(t *testing.T) {
    arg1 := os.Args[1]
    if arg1 != &quot;baz&quot; {
        t.Errorf(&quot;Expected baz do not match actual %s&quot;, arg1)
    }
}</code></pre>
<p>Here we retrieve the second command-line argument. Note that <span v-highlightjs><code class="go" v-pre style="display: inline">os.Args</code></span> is a slice of strings (<span v-highlightjs><code class="go" v-pre style="display: inline">[]string</code></span>), and the first index (0) is occupied by internal values of the go test command line (the emplacement of the cached build).</p>
<p>To pass arguments when the test run, we can use the flag <strong>-args :</strong></p>
<pre v-highlightjs><code class="go" v-pre >$ go test gitlab.com/loir402/foo -args bar</code></pre>
<p>The execution result is the following :</p>
<pre v-highlightjs><code class="go" v-pre >--- FAIL: TestArgs (0.00s)
    foo_test.go:24: Expected baz do not match actual bar
FAIL
FAIL    gitlab.com/loir402/foo  0.005s</code></pre>
<p>You can add as many flags as you want with this method. Note that <span v-highlightjs><code class="go" v-pre style="display: inline">-args</code></span> is not part of the cacheable flags.</p>
<div id="flags" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Flags <a href="#flags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can pass all the existing build flags to the go test command line. In addition to that, specific testing flags are available.</p>
<p>We intentionally do not cover benchmark-specific flags. We will explain them in a dedicated chapter.</p>
<div id="coverage-flags" class="anchor"></div>
<h4 data-number="13.2.0.1"><span class="header-section-number">13.2.0.1</span> Coverage flags <a href="#coverage-flags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<div class="list">
<p>This flag will display the coverage data analysis. It’s, in my opinion, the most important flag to know. The coverage data give you a statistic about the percentage of statements of your code that is covered by a unit test :</p>
</div>
<pre v-highlightjs><code class="go" v-pre >$ go test -cover
PASS
coverage: 100.0% of statements
ok      gitlab.com/loir402/foo  0.005s</code></pre>
<div class="list">
<p>This flag allows you to choose a method to compute the coverage percentage. (the default is “set”, other values available are “count” or “atomic”). For more information about the computation method, see the specific section.</p>
<p>You can specify that coverage data will be computed only for a subset of your project’s package.</p>
<p>write somewhere the cover data</p>
</div>
<div id="test-run-flags" class="anchor"></div>
<h4 data-number="13.2.0.2"><span class="header-section-number">13.2.0.2</span> Test run flags <a href="#test-run-flags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<div class="list">
<p>With this flag, when the first test break, all the other tests are not run. This is useful when you want to debug your code and fix problems one by one (when they happen)</p>
<p>This flag will define the maximum number of tests that will be run in parallel. By default, it is set to the variable GOMAXPROCS</p>
<p>By default, the timeout is set to 10 minutes when you run your tests. Consequently, unit tests that run for more than 10 minutes will panic. If your test suite needs more than 10 minutes to be executed, you can overwrite that setting and set a specific duration (the value of this flag is a string that will be parsed as a time.Duration)</p>
<p>Verbose mode will display the names of the test functions as they are run :</p>
</div>
<p>Here is an example output of the verbose mode :</p>
<pre v-highlightjs><code class="go" v-pre >=== RUN   TestCorge1
=== PAUSE TestCorge1
=== RUN   TestCorge2
=== PAUSE TestCorge2
=== RUN   TestCorge3
=== PAUSE TestCorge3
=== CONT  TestCorge1
=== CONT  TestCorge3
=== CONT  TestCorge2
--- PASS: TestCorge2 (0.31s)
--- PASS: TestCorge3 (0.31s)
--- PASS: TestCorge1 (0.31s)
PASS
ok      gitlab.com/loir402/corge    0.311s</code></pre>
<p>The log is pretty long for three tests because we run them in parallel. Tests that are not running in parallel do not log the PAUSE and CONT steps :</p>
<pre v-highlightjs><code class="go" v-pre >=== RUN   TestCorge1
--- PASS: TestCorge1 (0.31s)
=== RUN   TestCorge2
--- PASS: TestCorge2 (0.31s)
=== RUN   TestCorge3
--- PASS: TestCorge3 (0.31s)
PASS
ok      gitlab.com/loir402/corge    0.921s</code></pre>
<div class="list">
<p>When you run your test go will automatically run go vet for a set of common errors (see <a href="#subsec:Go-testvet" data-reference-type="ref" data-reference="subsec:Go-testvet">[subsec:Go-testvet]</a>). If you want to deactivate it totally (I do not recommend), you can set this flag to off. But you can also add complimentary checks.</p>
</div>
<div id="profiling-flags" class="anchor"></div>
<h4 data-number="13.2.0.3"><span class="header-section-number">13.2.0.3</span> Profiling flags <a href="#profiling-flags"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The go test command line also defines specific flags to identify performances issues of your code. Those flags are covered in the specific chapter “Profiling”</p>
<div id="code-coverage" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Code Coverage <a href="#code-coverage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Is a project sufficiently tested?</p></li>
<li><p>What defines a good test level?</p></li>
<li><p>How to measure the testing level of a project?</p></li>
</ul>
<p>Code coverage answers those questions. Code coverage is a measure of how a project is tested. It is often given as a percentage.</p>
<p>The measure’s definition is not unique, and different code coverage definitions exist.</p>
<p>The go tool can compute this code coverage statistic for you. Three modes (or computation methods) are available.</p>
<p>To output the test coverage of your code, run the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -cover
PASS
coverage: 66.7% of statements

ok      go_book/testCoverage    0.005s</code></pre>
<p>You see that a new line has been added to the test summary. It gives the percentage of code coverage.</p>
<p>We will go through this figure’s different computation methods in the next sections.</p>
<div id="cover-mode-set" class="anchor"></div>
<h2 data-number="14.1"><span class="header-section-number">14.1</span> Cover mode set <a href="#cover-mode-set"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This is the default mode. In literature, we call this mode the “statement coverage” that because it counts the percentage of executed statements in tests <b-link class="citation" data-cites="zhu1997software" href="#zhu1997software" >[@zhu1997software]</b-link>.</p>
<p>Perfect test coverage is 100%, meaning that all the code statements have been tested.</p>
<p>Let’s take an example with the following code :</p>
<pre v-highlightjs><code class="go" v-pre >package testCoverage

func BazBaz(number int) int {
    if number &lt; 10 {
        return number
    } else {
        return number
    }
}</code></pre>
<p>This package defines one single function. Inside this function, a conditional statement discriminates two cases. Input numbers under ten and numbers above.</p>
<p>Let’s write a test :</p>
<pre v-highlightjs><code class="go" v-pre >func TestBazBaz(t *testing.T) {
    expected := 3
    actual := BazBaz(3)
    if actual != expected {
        t.Errorf(&quot;actual %d, expected %d&quot;, actual, expected)
    }
}</code></pre>
<p>In this unit test, we execute <span v-highlightjs><code class="go" v-pre style="display: inline">BazBaz</code></span> with the number 3 as input.</p>
<p>Let’s run the test :</p>
<pre v-highlightjs><code class="go" v-pre >go test -cover
PASS
coverage: 66.7% of statements

ok      go_book/testCoverage    0.005s</code></pre>
<p>We only covered 66.7% of statements.</p>
<div id="cover-profile" class="anchor"></div>
<h4 data-number="14.1.0.1"><span class="header-section-number">14.1.0.1</span> Cover Profile <a href="#cover-profile"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>To help us understand the computation go can generate a coverprofile, which is a file detailing which statements are covered.</p>
<figure>
<b-img :src="require('@/assets/images/coverprofile.png')" alt="Coverprofile HTML generated[fig:Coverprofile-HTML-generated]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Coverprofile HTML generated<span id="fig:Coverprofile-HTML-generated" label="fig:Coverprofile-HTML-generated">[fig:Coverprofile-HTML-generated]</span></figcaption>
</figure>
<p>To generate this file, you have to use two commands in your terminal :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -coverprofile profile</code></pre>
<p>This first command will generate the profile file :</p>
<pre v-highlightjs><code class="go" v-pre >mode: set
unit-test/coverage/testCoverage.go:3.29,4.17 1 1
unit-test/coverage/testCoverage.go:4.17,6.3 1 1
unit-test/coverage/testCoverage.go:6.8,8.3 1 0</code></pre>
<p>This file details the blocks of code of your application. Each line represent a “block”. At the end of each line you can see two figures: the total number of statements and the number of statements covered (see figure <a href="#fig:Coverprofile-file" data-reference-type="ref" data-reference="fig:Coverprofile-file">2</a>)</p>
<figure>
<b-img :src="require('@/assets/images/coverprofile_schema.png')" alt="Coverprofile file[fig:Coverprofile-file]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Coverprofile file<span id="fig:Coverprofile-file" label="fig:Coverprofile-file">[fig:Coverprofile-file]</span></figcaption>
</figure>
<p>This file is not easily readable. From this file, you can generate a nice HTML file like in the figure <a href="#fig:Coverprofile-HTML-generated" data-reference-type="ref" data-reference="fig:Coverprofile-HTML-generated">1</a>. To do this, type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool cover -html=profile</code></pre>
<p>It will create an HTML page, store it (not in your project directory) and open it on a browser.</p>
<div id="increase-the-coverage" class="anchor"></div>
<h4 data-number="14.1.0.2"><span class="header-section-number">14.1.0.2</span> Increase the coverage <a href="#increase-the-coverage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>We have a total of three statements, and two are covered: the first if statement then the first return. It means that <span class="math inline">\frac{2}{3}</span> (or 66.7%) of statements are covered.</p>
<p>We can increase that percentage to 100% by integrating a test of the remaining statement (the else part of our condition) :</p>
<pre v-highlightjs><code class="go" v-pre >func TestBazBaz2(t *testing.T) {
    expected := 25
    actual := BazBaz(25)
    if actual != expected {
        t.Errorf(&quot;actual %d, expected %d&quot;, actual, expected)
    }
}</code></pre>
<p>This will lead to a coverage of 100%. All the statements of our code are covered.</p>
<div id="cover-mode-count" class="anchor"></div>
<h2 data-number="14.2"><span class="header-section-number">14.2</span> Cover mode count <a href="#cover-mode-count"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The <strong>count</strong> mode is similar to the <strong>set</strong> mode. With this mode you can detect if some part of the code is covered by more tests than others.</p>
<p>For instance, the function :</p>
<pre v-highlightjs><code class="go" v-pre >func BazBaz(number int) int {
    if number &lt; 10 {
        return number
    } else {
        return number
    }
}</code></pre>
<p>is tested by two test cases :</p>
<ul>
<li><p>One that will test an input less than 10</p></li>
<li><p>One that will test an input greater than 10.</p></li>
</ul>
<p>All statements are covered, but the first one (the conditional statement if) is tested twice. During the execution of the second test, the test <strong>number &lt; 10</strong> is evaluated.</p>
<p>The conditional statement is “more” tested than the other one.</p>
<p>The coverprofile in count mode has not the same layout as you can see in figure <a href="#fig:Coverprofile-Count-mode" data-reference-type="ref" data-reference="fig:Coverprofile-Count-mode">3</a>.</p>
<figure>
<b-img :src="require('@/assets/images/coverprofile_count.png')" alt="Coverprofile Count mode[fig:Coverprofile-Count-mode]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Coverprofile Count mode<span id="fig:Coverprofile-Count-mode" label="fig:Coverprofile-Count-mode">[fig:Coverprofile-Count-mode]</span></figcaption>
</figure>
<p>The greener the statement is, the more it’s tested.</p>
<p>The coverprofile has the same layout, but the last figure represents the number of time the statement is tested :</p>
<pre v-highlightjs><code class="go" v-pre >mode: count
unit-test/coverage/testCoverage.go:3.29,4.17 1 2
unit-test/coverage/testCoverage.go:4.17,6.3 1 1
unit-test/coverage/testCoverage.go:6.8,8.3 1 1</code></pre>
<p>On the second line of this profile, you can see that the first code block (starts at 3.29 and ends at 4.17) has 1 statement tested two times.</p>
<div id="cover-mode-atomic-advanced" class="anchor"></div>
<h2 data-number="14.3"><span class="header-section-number">14.3</span> Cover mode atomic (advanced) <a href="#cover-mode-atomic-advanced"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The last cover mode is count. It is useful when you build concurrent programs. Internally the system will use atomic counters (instead of simple counters). With those concurrent safe counters, the coverprofile will be <strong>more precise.</strong></p>
<p>To demonstrate it, I have modified the <span v-highlightjs><code class="go" v-pre style="display: inline">BazBaz</code></span> function to make it even more silly by adding useless goroutines :</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/coverage/testCoverage.go
package testCoverage

import (
    &quot;fmt&quot;
    &quot;sync&quot;
)

func BazBaz(number int) int {
    var waitGroup sync.WaitGroup
    for i := 0; i &lt; 100; i++ {
        waitGroup.Add(1)
        go concurrentTask(number, &amp;waitGroup)
    }
    waitGroup.Wait()
    return number
}

func concurrentTask(number int, waitGroup *sync.WaitGroup) {
    useless := number + 2
    fmt.Println(useless)
    waitGroup.Done()
}</code></pre>
<p>We will launch 100 useless concurrent tasks that just make an assignment: set useless to the number + 2. We use waitgroups to ensure that all our concurrent tasks will execute before the program ends. We do not modify the unit tests.</p>
<p>Let’s get the coverprofile in count mode :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -coverprofile profileCount -covermode count
$ cat profileCount
mode: count
go_book/testCoverage/testCoverage.go:8.29,10.27 2 2
go_book/testCoverage/testCoverage.go:14.2,15.15 2 2
go_book/testCoverage/testCoverage.go:10.27,13.3 2 200
go_book/testCoverage/testCoverage.go:18.60,22.2 3 197</code></pre>
<p>And in atomic mode :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test -coverprofile profileAtomic -covermode atomic
$ cat profileAtomic
mode: atomic
go_book/testCoverage/testCoverage.go:8.29,10.27 2 2
go_book/testCoverage/testCoverage.go:14.2,15.15 2 2
go_book/testCoverage/testCoverage.go:10.27,13.3 2 200
go_book/testCoverage/testCoverage.go:18.60,22.2 3 200</code></pre>
<p>If we use the count mode, the result is not accurate. For the last block of code (from 18.60 to 22.2), the count mode has found that we have tested the statement 197 times. The atomic mode has found that we have tested it 200 times which is the correct value.</p>
<p>Note that this cover mode will add overhead to the coverprofile creation.</p>
<div id="test-driven-development-tdd" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Test-Driven Development (TDD) <a href="#test-driven-development-tdd"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Test-Driven Development (or TDD) is a <strong>development method</strong> where you design the tests before actually writing the software.</p>
<p>Historically this method has emerged with the development of the XP methodology in the late nineties. This method has been widespread among the community, and authors like Robert C. Martin<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a> have contributed to its adoption.</p>
<p>Let’s jump right away to an example in Go. Our objective is to build a function that will count the number of vowels in a string. We first begin by creating a test case (that will fail because we have not created the function :</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/tdd/tdd_test.go
package tdd

import &quot;testing&quot;

func TestVowelCount(t *testing.T) {
    expected := uint(5)
    actual := VowelCount(&quot;I love you&quot;)
    if actual != expected {
        t.Errorf(&quot;actual %d, expected %d&quot;, actual, expected)
    }
}</code></pre>
<p>Here we are calling the function <span v-highlightjs><code class="go" v-pre style="display: inline">VowelCount</code></span> with the sentence <span v-highlightjs><code class="go" v-pre style="display: inline">"I love you"</code></span>. In this sentence, we have five vowels; our expected result is the integer 4. As usual, we compare the actual number and the expected.</p>
<p>Let’s run our test to see what happens :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test
# go_book/tdd [go_book/tdd.test]
./tdd_test.go:7:12: undefined: VowelCount
FAIL    go_book/tdd [build failed]</code></pre>
<p>We cannot compile; the test fails.</p>
<p>Now we can implement our function. We start by creating a map of vowels from the alphabet.</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/tdd/tdd.go
package tdd

var vowels = map[string]bool{
    &quot;a&quot;: true,
    &quot;e&quot;: true,
    &quot;i&quot;: true,
    &quot;o&quot;: true,
    &quot;u&quot;: true}</code></pre>
<p>Then we create the function. It will take each letter of the sentence iteratively and check if the vowel is on the map :</p>
<pre v-highlightjs><code class="go" v-pre >// unit-test/tdd/tdd.go
package tdd

//...

func VowelCount(sentence string) uint {
    var count uint
    for _, char := range sentence {
        if vowels[string(char)] {
            count++
        }
    }
    return count
}</code></pre>
<p>The char is the Unicode code point. That’s why we have to convert it to a string to make our script work. Let’s run the test again to see if our implementation works :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test
--- FAIL: TestVowelCount (0.00s)
    tdd_test.go:9: actual 4, expected 5
FAIL
exit status 1

FAIL    go_book/tdd     0.005s</code></pre>
<p>It seems not to work. What could be wrong with our code? One letter seems to be skipped. There is something we missed, here our test string comes with the I capitalized, but we compare it to lowercase letters. That’s a bug. We want to count also capitalized vowels.</p>
<p>What have to options :</p>
<ol type="1">
<li><p>Add the uppercase letters to our map.</p></li>
<li><p>Each letter should be converted to lowercase and then compared with the existing map.</p></li>
</ol>
<p>The second solution seems to be less efficient than the first :</p>
<ul>
<li><p>In the first option, we have to spend precious time converting each letter,</p></li>
<li><p>Whereas in the second solution, we are just performing a map lookup which is really fast (O(1)<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a>).</p></li>
</ul>
<p>Let’s add the capitalized vowels to our map :</p>
<pre v-highlightjs><code class="go" v-pre >var vowels = map[string]bool{
    //...
    &quot;A&quot;: true,
    &quot;E&quot;: true,
    &quot;I&quot;: true,
    &quot;O&quot;: true,
    &quot;U&quot;: true}</code></pre>
<p>Then we run our test again, and it works !</p>
<pre v-highlightjs><code class="go" v-pre >$ go test
PASS

ok      go_book/tdd     0.005s</code></pre>
<div id="advantages" class="anchor"></div>
<h4 data-number="15.0.0.1"><span class="header-section-number">15.0.0.1</span> Advantages <a href="#advantages"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>The developer is forced to create a function that <strong>can be tested</strong>.</p></li>
<li><p>When we wrote our test, we have chosen our function’s signature. This is an act of design focused on the use of our functionality. In a certain measure, we are designing our API in an <strong>user-centric approach</strong>. We are using our API before actually implementing it forces us to keep things simple and usable.</p></li>
<li><p>With this method <strong>every single function of our source code is tested</strong>.</p></li>
</ul>
<p>You might argue that this way of developing is not very natural. This feeling is normal. Many developers (especially young ones) that I have met are reluctant to do the tests and prefer to develop the functionality in a minimal amount of time.</p>
<div id="facts-and-figures" class="anchor"></div>
<h4 data-number="15.0.0.2"><span class="header-section-number">15.0.0.2</span> Facts and figures <a href="#facts-and-figures"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>To convince you of this approach, I will focus on the fact and on real studies that have been conducted about TDD. (Those results have been extracted from the very good article of David Janzen <b-link class="citation" data-cites="janzen2005test" href="#janzen2005test" >[@janzen2005test]</b-link> that was published in 2005) :</p>
<ul>
<li><p>Using TDD has generated a reduction of 50% of defect density for a company composed of 9 developers. With a minimal impact on productivity.<b-link class="citation" data-cites="maximilien2003assessing" href="#maximilien2003assessing" >[@maximilien2003assessing]</b-link></p></li>
<li><p>For another company, the reduction percentage was 40%, with no impact at all on the productivity of the team (9 developers) <b-link class="citation" data-cites="williams2003test" href="#williams2003test" >[@williams2003test]</b-link>.</p></li>
<li><p>The use of unit test leads to a better information flow in the company <b-link class="citation" data-cites="kaufmann2003implications" href="#kaufmann2003implications" >[@kaufmann2003implications]</b-link>.</p></li>
<li><p>Another study that was conducted in a class of undergraduate class of computer science has demonstrated a reduction of 45% of defects per thousand lines of code <b-link class="citation" data-cites="edwards2003using" href="#edwards2003using" >[@edwards2003using]</b-link>.</p></li>
</ul>
<p>I hope you are convinced.</p>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="16.1"><span class="header-section-number">16.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>In a Go program where test files are stored?</p></li>
<li><p>You develop a function named <span v-highlightjs><code class="go" v-pre style="display: inline">ShippingCost</code></span>. What is the conventional signature of its test function?</p></li>
<li><p>In a test, you need to load data from a separate file; where can you store it?</p></li>
<li><p>What is an assertion? Give an example.</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="16.2"><span class="header-section-number">16.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>In a Go program where test files are stored?</p>
<ol type="1">
<li>Test files are stored in the same directory as the package source files.</li>
</ol></li>
<li><p>You develop a function named <span v-highlightjs><code class="go" v-pre style="display: inline">ShippingCost</code></span>. What is the conventional signature of its test function?</p>
<pre v-highlightjs><code class="go" v-pre >func TestShippingCost(t *testing.T)</code></pre>
<ol type="1">
<li><p>Note the string <span v-highlightjs><code class="go" v-pre style="display: inline">"Test"</code></span> at the beginning of the function, this is mandatory</p></li>
<li><p>The characters after “Test” are free, but they must begin with a capitalized letter or an underscore (_)</p></li>
</ol></li>
<li><p>In a test, you need to load data from a separate file; where can you store it?</p>
<ol type="1">
<li><p>You can create a directory named “<strong>testdata</strong>” in the directory containing the source files of the package.</p></li>
<li><p>You can put in this folder files loaded in your test cases.</p></li>
</ol></li>
<li><p>What is an assertion? Give an example.</p>
<ol type="1">
<li><p>In the context of unit tests, an assertion is a boolean expression (i.e., An expression that will be evaluated to true or false).</p></li>
<li><p>A “traditional” example is :</p></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >actual == expected</code></pre></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Writing unit tests is a good practice :</p>
<ul>
<li><p>It gives relative protection against regressions.</p></li>
<li><p>Unit tests may detect bugs before they appear in production.</p></li>
<li><p>Improve the design of your package’s API</p></li>
</ul></li>
<li><p>Unit tests live inside packages.</p></li>
<li><p>Tests are written on test files.</p></li>
<li><p><strong>xxx_test.go</strong> is the conventional name of a test file.</p></li>
<li><p>Test files can contain multiple test functions</p></li>
<li><p>A test function can contain several test cases</p></li>
<li><p>The name of a test function should begin with <span v-highlightjs><code class="go" v-pre style="display: inline">Test</code></span>, the next characters is either an underscore or a capitalized letter</p></li>
<li><p>The name of the function under test is generally contained in the test function name.</p></li>
<li><p>A test function has the following signature :</p>
<pre data-float=""><span v-highlightjs><code class="go" v-pre style="display: inline">func TestShippingCost(t *testing.T)</code></span></pre></li>
<li><p>Table tests are a convenient way to test several test cases in a single function.</p></li>
<li><p>Test-Driven Development is a method that can increase the quality of your code and reduce defects.</p>
<ul>
<li><p>The unit test is written before the actual implementation</p></li>
<li><p>The unit test fails first; the aim is then to make it pass.</p></li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Nickname: Uncle bob<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>This is the Big O notation<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap19UnitTests"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap18GoModuleProxies'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Go Module Proxies</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap20Arrays'}">
									<p><u><small>Next</small></u></p>
									<p><small>Arrays</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Unit Tests - Practical Go Lessons"
const description = "unit testing is the “testing of individual hardware or software units or groups of related units”. We will learn how to create and launch unit tests with Go."

export default {
  name: "Chap19UnitTests",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
